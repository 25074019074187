<template>
	<div>
		<div class="faq_first_container wrapper_container bg_center">
			<div class="main_content">
				<h1 class="ke_common_title">Frequently Asked Questions</h1>
			</div>
		</div>
		<div class="faq_two_container wrapper_container">
			<div class="main_content">
				<h2>How does an advance help employees manage their money better?</h2>
				<p>
					It isn’t an advance. It is their money that they have already earned. Why should they have to wait two weeks
					for the money they earn each day? Labor is rendered in real time. Why not wages?
				</p>
				<h2>How is EWA beneficial to the employer?</h2>
				<p>
					EWA benefits employers via increased retention, engagement, and productivity. It also helps with recruitment.
					Seventy-eight percent of employees want a financial wellness benefit, such as EWA.
				</p>
				<h2>Won’t it affect the cash flow if employees are paid mid-month?</h2>
				<p>MIB offers the accessed wages, not the employer.</p>
				<h2>I have a compliance concern that this is an advance or loan to the employee?</h2>
				<p>
					EWA is not a loan. It gives the worker access to wages earned but not yet paid on. Neither gross reportable or
					net received pay changes.
				</p>
				<h2>Won’t this affect the P/L and creation of receivables in payroll accounts?</h2>
				<p>
					No. The EWA provider handles accessed wages, not the employer. There is no receivable because EWA is not a
					loan made by either the employer or MIB.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">
.faq_first_container {
	position: relative;
	height: 990px;
	background-image: url('~@/assets/ke-img/FAQ/first-bg.webp');
	.main_content {
		position: absolute;
		bottom: 120px;
		left: 50%;
		transform: translateX(-50%);
		h1 {
			text-align: center;
			font-size: 80px;
			color: #fff;
			text-shadow: 0px 4px rgba(0, 0, 0, 0.5);
		}
	}
}
.faq_two_container {
	.main_content {
		padding: 200px 0;
		h2 {
			font-size: 35px;
			color: #555770;
			font-weight: bold;
			line-height: 50px;
			&:not(:first-child) {
				margin-top: 50px;
			}
		}
		p {
			font-size: 30px;
			color: #555770;
			padding: 30px 0;
			line-height: 50px;
		}
	}
}
@media screen and (max-width: 1246px) {
	.faq_first_container {
		margin-top: 100px;
		height: 430px;
	}
	.faq_two_container {
		.main_content {
			padding: 150px 0;
		}
	}
}
</style>
